<template>
  <mosaic-card>
    <mosaic-card-title>Instructor Training</mosaic-card-title>
    <div class="d-flex py-2 align-center flex-wrap">
      <mosaic-tabs :headers="tabHeaders" @tab-selected="currentTab = $event">
        <template v-for="h in tabHeaders" #[h.key]>
          <slot :name="`${h.key}-tab-item`"> </slot>
        </template>
      </mosaic-tabs>
    </div>

    <mosaic-table
      v-if="currentTab === 'moduleCompletion'"
      table-id="mc"
      :columns="columns"
      :rows="props.trainingData"
      object-type-pluralised="Instructors"
      no-data-suffix="who have started or completed any Modules in this Cohort"
      :export-config="{ title: 'Module Completion' }"
    />

    <mosaic-table
      v-if="currentTab === 'trainingTime'"
      table-id="tt"
      :not-applicable-message="{
        text: 'N/A',
        tooltip: 'This Instructor has not completed Training that matches the selected types and Frameworks.',
      }"
      :export-config="{ title: 'Training Time' }"
      :select-filters="[
        {
          affectedColumns: ['timePerType', 'totalTime'],
          initial: [1, 2, 3],
          multiple: true,
          key: 'typeOfTraining',
          items: Object.values(typeItems),
          name: 'Filter by type',
        },
        {
          affectedColumns: ['timePerType', 'totalTime'],
          initial: frameworks?.map(f => f.id) || [],
          multiple: true,
          itemDetails: true,
          items: frameworksItems,
          name: `Filter by Framework`,
          key: 'frameworks',
        },
      ]"
      :columns="trainingTimeDataColumns"
      :rows="trainingTimeDataRows"
      :data="$props.trainingTimeData"
      object-type-pluralised="Instructors"
      no-data-suffix="with Training assigned in this Cohort"
      :graph-labels="Object.values(typeItems)"
    />
  </mosaic-card>
</template>

<script setup lang="ts">
import MosaicTable from '@/components/monitoring/MosaicTable.vue';
import type { Column, EnumColumn, Row, SelectFilterValue } from '@/components/monitoring/mosaic-table';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import { ref, computed } from 'vue';
import { hasPermission } from '@/mixins/global-mixins';
import { mapState } from '@/store/map-store';
import { useRoute } from 'vue-router';
import { icons } from '@/utils/icons';
import {
  addTime,
  formatTimeToString,
  convertMinutesToHoursAndMinutes,
  calculateTotalTime,
  formatTimeStringInParenthesis,
} from '@/utils/time';

export type Framework = {
  id: number;
  name: string;
};

export type TypeOfTraining = {
  id: number;
  name: string;
  frameworks: Framework[];
  duration_hours: number;
  duration_minutes: number;
};

export type Role = { id: number; name: string };

export type StaffTrainingDetails = {
  [propName: string]: string | number | TypeOfTraining[] | Role[];
  id: number;
  name: string;
  roles: Role[];
  staff_id: number;
  attended_events: TypeOfTraining[];
  completed_modules: TypeOfTraining[];
  training_records: TypeOfTraining[];
};

type StaffTrainingData = {
  id: number;
  name: string;
  roles: string[];
} & Record<string, { value: 'Not Started' | 'Started' | 'Completed' }>;

const props = defineProps<{
  modules: { id: number; name: string }[];
  trainingData: StaffTrainingData[];
  trainingTimeData: StaffTrainingDetails[];
}>();

const {
  institutionStaffTrainingFrameworks: frameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

loadInstitutionStaffTrainingFrameworks();

const { userStaff, selectedInstitution, selectedCohort } = mapState();

const typeItems = {
  attended_events: {
    value: 1,
    name: 'Events',
    type: 'attended_events',
    background: `linear-gradient(to right, #6ea37c, #669c75, #5e956e)`,
    color: '#6ea37c',
  },
  completed_modules: {
    value: 2,
    name: 'Modules',
    type: 'completed_modules',
    background: `linear-gradient(to right, #6290cc, #517bb5, #456aa0)`,
    color: '#6290cc',
  },
  training_records: {
    value: 3,
    name: 'Manually Recorded Training',
    type: 'training_records',
    background: `linear-gradient(to right, #be69ab, #b560a2, #ac5799)`,
    color: '#be69ab',
  },
};
const frameworksItems = computed(() => {
  if (!frameworks || !frameworks.value) return [];
  return frameworks.value.map(f => {
    const existsInCurrentCohort = f.staffTrainingFrameworkCohorts.some(
      c => c.publishedToCohortId === selectedCohort.value.id
    );
    return {
      value: f.id,
      name: f.name,
      chipIcon: existsInCurrentCohort ? 'mdi-' + icons.cohort : null,
      chipTooltip: existsInCurrentCohort ? `This Framework is published to the current Cohort` : null,
    };
  });
});

const route = useRoute();
const currentTab = ref(route.query.tab);
const tabHeaders = computed(() => [
  { key: 'moduleCompletion', text: 'Module Completion', dirty: false },
  { key: 'trainingTime', text: 'Training Time', dirty: false },
]);

const recalculateFilteredResults = (r: Row, f: SelectFilterValue, typeToReturn: string) => {
  let totalTimeMinutes = 0;
  let totalTimeHours = 0;
  const items = {
    attended_events: {
      type: 'attended_events',
      title: 'Events',
      background: typeItems.attended_events.background,
      tooltip: { title: 'Events', lists: [] as string[] },
      width: 0,
      value: 0,
      label: '',
    },
    completed_modules: {
      type: 'completed_modules',
      title: 'Modules',
      background: typeItems.completed_modules.background,
      tooltip: { title: 'Modules', lists: [] as string[] },
      width: 0,
      value: 0,
      label: '',
    },
    training_records: {
      type: 'training_records: ',
      title: 'Manually Recorded Training',
      background: typeItems.training_records.background,
      tooltip: { title: 'Manually Recorded Training', lists: [] as string[] },
      width: 0,
      value: 0,
      label: '',
    },
  };

  f.typeOfTraining.forEach(type => {
    const typeName = type === 1 ? 'attended_events' : type === 2 ? 'completed_modules' : 'training_records';

    r.fullRowData[typeName].forEach((training: TypeOfTraining) => {
      const atLeastOneCommonFilter = training.frameworks.some(framework => f.frameworks.includes(framework.id));
      if (atLeastOneCommonFilter) {
        //update the total time
        ({ hours: totalTimeHours, minutes: totalTimeMinutes } = addTime(
          { hours: totalTimeHours, minutes: totalTimeMinutes },
          { hours: training.duration_hours, minutes: training.duration_minutes }
        ));
        items[typeName].value += training.duration_hours * 60 + training.duration_minutes;
        items[typeName].tooltip.lists.push(
          `${training.name} ${formatTimeStringInParenthesis(training.duration_hours, training.duration_minutes)}`
        );
      }
    });
    items[typeName].width =
      items[typeName].value !== 0
        ? items[typeName].value / 5
        : items[typeName].value === 0 && items[typeName].tooltip.lists.length > 0
        ? 4
        : 0;
    const timeOnType = formatTimeToString(Math.floor(items[typeName].value / 60), items[typeName].value % 60);
    items[typeName].tooltip.title = `${items[typeName].title} (${timeOnType})`;
    items[typeName].label +=
      items[typeName].value !== 0
        ? `${items[typeName].title}: ${timeOnType}`
        : items[typeName].value === 0 && items[typeName].tooltip.lists.length > 0
        ? `${items[typeName].title}: Training with a duration of zero`
        : '';
  });

  const { hours: finalTotalHours, minutes: finalTotalMinutes } = convertMinutesToHoursAndMinutes(
    totalTimeHours * 60 + totalTimeMinutes
  );

  return typeToReturn === 'string'
    ? formatTimeToString(finalTotalHours, finalTotalMinutes) //time labels
    : typeToReturn === 'number'
    ? calculateTotalTime(finalTotalHours, finalTotalMinutes) //for range
    : Object.values(items).flat();
};

const rolesInTraining = computed(() => {
  const allRoles = new Map();
  props.trainingTimeData.flatMap(data => data.roles.forEach(r => allRoles.set(r.id, { title: r.name, value: r.id })));
  return Array.from(allRoles.values());
});

const redirect = (row: Row) => {
  return {
    name: 'TutorStaffPage',
    params: {
      institutionId: selectedInstitution.value.id.toString(),
      id: (row as StaffTrainingData).id.toString(),
    },
    query: {
      tab: 'training-requirements',
    },
  };
};

const trainingTimeDataColumns = [
  {
    key: 'name',
    name: 'Name',
    width: '20%',
    sort: (r: Row) => r.name,
    sticky: true,
    text: (r: Row) => r.name,
    clickRoute: hasPermission(userStaff.value, 'staff.edit') ? (r: Row) => redirect(r) : undefined,
  },
  {
    key: 'roles',
    name: 'Cohort Roles',
    width: '20%',
    multipleChips: (r: { roles: Role[] }) =>
      r.roles.map(cv => {
        return {
          text: cv.name,
          value: cv.id,
          color: 'primary',
        };
      }),
    isRoles: true,
    filter: {
      type: 'select',
      multiple: true,
      items: rolesInTraining.value,
      minWidth: '140px',
      value: (r: { roles: Role[] }) => r.roles.map(cv => cv.id),
    },
  },
  {
    key: 'totalTime',
    name: 'Total Time',
    width: '20%',
    value: (r: Row, f: SelectFilterValue) => r.totalTime.value(r, f),
    friendlyAlternativeValue: (r: Row, f: SelectFilterValue) => r.totalTime.friendlyAlternativeValue(r, f),
    filter: {
      type: 'range',
      value: (r: Row, f: SelectFilterValue) => r.totalTime.value(r, f),
    },

    SelectFilterValue: { 0: 0, 1: null },
  },

  {
    key: 'timePerType',
    name: 'Time per Type',
    items: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'types'),
  },
] as Column[];

const trainingTimeDataRows = props.trainingTimeData.map(md => {
  const types: { [key: string]: { value: string } } = {};
  const frameworks: { [key: string]: { value: string } } = {};

  Object.values(typeItems).forEach(item => {
    const data = md[item.type];
    if (Array.isArray(data)) {
      types[item.value] = { value: item.type };
      data.forEach(t => {
        if ('frameworks' in t && Array.isArray(t.frameworks))
          t.frameworks.forEach(f => (frameworks[f.id] = { value: f.name }));
      });
    }
  });

  return {
    id: md.staff_id,
    name: md.name,
    roles: md.roles,
    typeOfTraining: types,
    frameworks,
    totalTime: {
      value: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'number'),
      friendlyAlternativeValue: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'string'),
    },
    timePerType: {
      items: [
        {
          key: 'attended_events',
          value: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'types'),
          width: (r: Row, f: SelectFilterValue) => (recalculateFilteredResults(r, f, 'types') as number) / 5,
          background: typeItems.training_records.background,
          tooltip: {
            title: 'Events',
            lists: md.attended_events.map(cv => {
              return `${cv.name} ${formatTimeStringInParenthesis(cv.duration_hours, cv.duration_minutes)}`;
            }),
          },
        },
        {
          key: 'completed_modules',
          value: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'types'),
          width: (r: Row, f: SelectFilterValue) => (recalculateFilteredResults(r, f, 'types') as number) / 5,
          background: typeItems.completed_modules.background,
          tooltip: {
            title: 'Modules',
            lists: md.completed_modules.map(cv => {
              return `${cv.name} ${formatTimeStringInParenthesis(cv.duration_hours, cv.duration_minutes)}`;
            }),
          },
        },

        {
          key: 'training_records',
          value: (r: Row, f: SelectFilterValue) => recalculateFilteredResults(r, f, 'types'),
          width: (r: Row, f: SelectFilterValue) => (recalculateFilteredResults(r, f, 'types') as number) / 5,
          background: typeItems.training_records.background,
          tooltip: {
            title: 'Recorded Training Records',
            lists: md.training_records.map(cv => {
              return `${cv.name} ${formatTimeStringInParenthesis(cv.duration_hours, cv.duration_minutes)}`;
            }),
          },
        },
      ],
    },
    fullRowData: { ...md },
  };
});

const statusColumns = props.modules.map<EnumColumn>(m => ({
  name: m.name,
  key: m.id.toString(),
  clickRoute: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    if (!data.value) return undefined;
    return {
      name: 'TutorCohortStaffTrainingModulePage',
      params: {
        staffId: staffTrainingData.id.toString(),
        moduleId: m.id.toString(),
      },
    };
  },
  chip: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    return {
      hide: !data.value,
      text: data.value,
      color: data.value === 'Completed' ? 'primary' : 'accent',
      value: data.value,
    };
  },
  filter: {
    type: 'select',
    items: [
      { title: 'All', value: null },
      { title: 'Completed', value: 'Completed' },
      { title: 'Not Completed', value: 'Not Completed' },
      { title: 'Not Assigned', value: 'Not Assigned' },
    ],
    value: (row: Row) => {
      const staffTrainingData = row as StaffTrainingData;
      const data = staffTrainingData[m.id];
      return data.value === 'Completed'
        ? 'Completed'
        : data.value === 'Started' || data.value === 'Not Started'
        ? 'Not Completed'
        : 'Not Assigned';
    },
  },
  sort: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    return data.value === 'Completed' ? 1 : data.value === 'Started' ? 2 : data.value === 'Not Started' ? 3 : 4;
  },
}));

const columns: Column[] = [
  {
    name: 'Name',
    key: 'name',
    sticky: true,
    clickRoute: hasPermission(userStaff.value, 'staff.edit') ? (r: Row) => redirect(r) : undefined,
    text: (row: Row) => (row as StaffTrainingData).name,
    secondaryText: (row: Row) => (row as StaffTrainingData).roles.join(', '),
  },
  ...statusColumns,
];
</script>
