<template>
  <div class="login-body">
    <div class="logo-left">
      <img src="@/assets/mosaic_icon.png" alt="Mosaic icon" class="logo" />
      <div class="about-mosaic">
        <div class="title">MOSAIC</div>
        <div class="subtitle">BY PENROSE EDUCATION</div>
      </div>
    </div>
    <div class="login-right">
      <v-card class="login-card" elevation="0">
        <slot name="loginContent"></slot>
      </v-card>
      <a target="blank" href="https://mosaic.penrose.education/">
        <div class="brand">
          <img class="brand-logo" src="@/assets/mosaic_icon.png" alt="Mosaic icon" />
          <div class="brand-text">MOSAIC BY PENROSE EDUCATION</div>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.brand {
  align-items: center;
  bottom: 0;
  display: none;
  gap: 10px;
  justify-content: end;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  width: 100vw;
  background: radial-gradient(circle at center, #8ec7cd, #57a4b0);
}

.brand-logo {
  height: 35px;
}

.brand-text {
  color: white;
  font-size: 13px;
  letter-spacing: 1.5px;
  white-space: nowrap;
}

.login-body {
  display: flex;
  height: 100vh;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}

.login-card {
  max-width: 500px;
  width: 70%;
}

.login-right {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;
  flex-direction: column;
}

.logo {
  max-width: 270px;
  width: 35%;
}

.logo-left {
  background: radial-gradient(circle at center, #8ec7cd, #57a4b0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  flex-direction: column;
}

.subtitle {
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 5px;
  padding-left: 0.3em;
  color: white;
}

.signup-message {
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.title {
  margin-top: -10px;
  font-size: 4em;
  font-weight: 300;
  letter-spacing: 10px;
  color: white;
}

@media (max-width: 992px) {
  .brand {
    display: flex;
  }

  .login-card {
    padding-bottom: 30px;
  }

  .logo-left {
    display: none;
  }

  .login-right {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .login-card {
    width: 90%;
    max-width: initial;
    padding-bottom: 10vh;
  }
}

@media (max-width: 500px) {
  .brand {
    justify-content: center;
  }
}
@media (max-height: 450px) {
  .brand-text {
    display: none;
  }
}
</style>
