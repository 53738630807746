<template>
  <div>
    <v-card class="mb-4">
      <div v-if="folder" class="py-1 px-2 d-flex">
        <div class="my-1 d-flex align-center">
          <v-icon v-if="processing" class="ml-3 mr-3 my-3">mdi-loading mdi-spin</v-icon>
          <ndt-icon-button v-else icon="reload" tooltip="Refresh" @click.prevent="updateItems()"></ndt-icon-button>
          <ndt-icon-button
            v-if="folderStack.length > 0"
            icon="arrow-up"
            tooltip="Up"
            @click.prevent="goUp()"
          ></ndt-icon-button>
        </div>
        <div class="my-1 flex-grow-1 text-left text-h6 ml-4 d-flex align-center flex-grow-1">
          {{ folder.name }}
        </div>
        <div
          v-if="folder.editable && storageSetUp"
          class="flex-shrink-1 text-right d-flex"
          :class="{
            'flex-column': smallScreen,
            'align-center': !smallScreen,
            'vertical-flex-spacing-small': smallScreen,
            'horizontal-flex-spacing-small': !smallScreen,
          }"
        >
          <v-btn v-if="canAddFile" ripple @click.prevent="addFilesDialog.active = true">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>File</span>
            </div>
          </v-btn>
          <v-btn ripple @click.prevent="createFolderDialog.active = true">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Folder</span>
            </div>
          </v-btn>
          <v-btn v-if="canOpenFolderInDrive" class="my-1 ml-2" ripple @click.prevent="openInDrive()">
            <div class="d-flex align-center">
              <v-icon v-if="filesStorageType === 'Google Drive'">mdi-google-drive</v-icon>
              <v-icon v-if="filesStorageType === 'One Drive'">mdi-microsoft-onedrive</v-icon>
              <span class="pl-1">Open In {{ filesStorageType === 'Google Drive' ? 'Google' : 'One' }} Drive</span>
            </div>
          </v-btn>
        </div>
        <v-btn
          v-if="backupSharingLink && !sharingLink && !showShareWarning"
          class="my-1 ml-2"
          ripple
          @click.prevent="backupAcceptSharing()"
        >
          <span>Accept Sharing</span>
        </v-btn>
      </div>
    </v-card>
    <mosaic-loading-card v-if="processing" type="list-no-filters" />
    <mosaic-load-error-card v-else-if="error" object-type="files" @retry="updateItems()" />
    <template v-else>
      <v-card>
        <div v-if="showShareWarning" class="pt-2">
          <mosaic-alert type="warning" class="mx-6 mb-0"
            ><div>
              The {{ traineeNounCapitalised() }}'s linked One Drive account is unable to share with your linked account
              due to Microsoft restrictions.
            </div>
            <div>
              You will therefore not be able to launch and view Files. Please contact support@penrose.education to
              resolve this issue.
            </div></mosaic-alert
          >
        </div>
        <div v-if="sharingLink && !showShareWarning" class="px-6 pb-6 pt-6">
          <div>To open this {{ traineeNoun() }}'s files, you first need to accept the sharing invitation.</div>
          <template v-if="storageEmail">
            <br />
            <div>
              Your linked {{ filesStorageType }} account:
              <span class="text-primary">{{ storageEmail }} </span>
            </div>
            <div>
              (This is the account with which your {{ traineeNoun() }}'s files stored on OneDrive will be shared. Please
              contact support if you do not think this is right.)
            </div>
          </template>
          <v-btn class="ml-2 my-4" ripple @click.prevent="acceptSharing()">Accept Sharing</v-btn>
          <div>
            Once you've done that, this should automatically transition to the {{ traineeNoun() }}'s files, if this
            doesn't happen, then please refresh the Files area.
          </div>
        </div>
        <template v-else>
          <div v-if="items.length === 0" class="pa-6">
            This folder does not contain any {{ canAddFile ? 'files' : 'folders' }}.
            <span v-if="folder.editable && canAddFile">Add some files using the "Files" button.</span>
            <span v-if="folder.editable && !canAddFile">Add some folders using the "Folder" button.</span>
          </div>
          <v-list v-else class="files-list">
            <v-list-item
              v-for="(item, index) in itemsWithMovable"
              :key="item.id"
              :to="item.to"
              v-on="item.type === 'file' ? { click: () => openFile(item) } : {}"
              :active="false"
              style="min-height: 58px"
            >
              <template #prepend>
                <v-avatar v-if="item.icon.tooltip">
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-icon :color="item.icon.color">mdi-{{ item.icon.name }}</v-icon>
                      </div>
                    </template>
                    {{ item.icon.tooltip }}
                  </v-tooltip>
                </v-avatar>
                <v-avatar v-else>
                  <v-icon :color="item.icon.color">mdi-{{ item.icon.name }}</v-icon>
                </v-avatar>
              </template>

              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ renderItemSubtitle(item) }}</v-list-item-subtitle>

              <template v-if="item.actions.length > 0 || item.canLinkEvidence || item.movable" #append>
                <div class="d-flex flex-row align-center">
                  <mosaic-evidence-icon-btn
                    v-if="item.canLinkEvidence"
                    v-model="item.evidence"
                    @click-edit="editEvidence(item)"
                    @click-create="linkToEvidence(item)"
                  />
                  <ndt-icon-button
                    :icon="item.actions[0].icon"
                    :tooltip="item.actions[0].label"
                    :color="item.actions[0].color"
                    @click.prevent="item.actions[0].click(item)"
                  />
                  <ndt-icon-button
                    v-if="item.showSecondAction"
                    :icon="item.actions[1].icon"
                    :tooltip="item.actions[1].label"
                    :color="item.actions[1].color"
                    @click.prevent="item.actions[1].click(item)"
                  />
                  <v-menu v-if="item.showMenu" location="left" width="130">
                    <template #activator="{ props: { ...rest } }">
                      <div :id="'menu-activator-' + index">
                        <ndt-icon-button icon="dots-vertical" tooltip="More actions" v-bind="rest" />
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="a in item.actions.slice(item.menuActionsSlice)"
                        :key="a.name"
                        style="cursor: pointer"
                        @click.prevent="
                          $event => {
                            $event.stopPropagation();
                            a.click(item);
                          }
                        "
                      >
                        <v-list-item-title>{{ a.label }}</v-list-item-title>
                      </v-list-item>
                      <v-menu v-if="item.movable" open-on-hover>
                        <template #activator="{ props }">
                          <v-list-item v-bind="props">
                            <v-list-item-title>Move</v-list-item-title>
                            <template #append>
                              <v-icon>mdi-chevron-right</v-icon>
                            </template>
                          </v-list-item>
                        </template>
                        <v-list>
                          <v-list-item v-for="f in moveTargets" :key="f.id" @click.prevent="move(item, f)">
                            <template #prepend>
                              <v-avatar>
                                <v-icon>mdi-{{ f.icon }}</v-icon>
                              </v-avatar>
                            </template>
                            <v-list-item-title>{{ f.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-list-item>
          </v-list>
        </template>
      </v-card>
    </template>

    <mosaic-snackbar v-model="errorSnackbar.active" color="error" :message="errorSnackbar.message" />

    <template v-if="folder">
      <add-files-dialog
        v-model:active="addFilesDialog.active"
        :on-close="updateItems"
        :folder="folder"
        :can-add-as-evidence="canAddAsEvidence"
        :upload-to-text="filesStorageType"
      />

      <create-folder-dialog v-model:active="createFolderDialog.active" :folder="folder" @close="updateItems" />

      <create-evidence-dialog
        v-model:active="createEvidenceDialog.active"
        :on-close="updateItems"
        :file-id="createEvidenceDialog.fileId"
        :title-placeholder="createEvidenceDialog.title"
      />

      <edit-evidence-dialog
        v-model:active="editEvidenceDialog.active"
        :evidence="editEvidenceDialog.evidence"
        :on-close="updateItems"
      />
      <edit-item-dialog v-model:active="editItemDialog.active" :item="editItemDialog.item" @close="updateItems" />
      <delete-item-dialog v-model:active="deleteItemDialog.active" :item="deleteItemDialog.item" @close="updateItems" />
    </template>
  </div>
</template>

<script>
import NdtIconButton from '../NdtIconButton.vue';
import AddFilesDialog from '../student/AddFilesDialog.vue';
import CreateEvidenceDialog from '../student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '../student/EditEvidenceDialog.vue';
import CreateFolderDialog from './CreateFolderDialog.vue';
import DeleteItemDialog from './DeleteItemDialog.vue';
import EditItemDialog from './EditItemDialog.vue';
import { fromNowOrNow } from '../../mixins/global-mixins';
import { syncQueryParamsMixin } from './files-list-sync-query-mixin';
import { mapState } from 'vuex';

export default {
  name: 'FilesList',
  mixins: [
    // This query state should be considered internal to the FilesList and shouldn't be used for external linking
    syncQueryParamsMixin(
      {
        urlFolderJSON: { query: 'folder', type: 'string' },
      },
      '',
      'push'
    ),
  ],
  components: {
    NdtIconButton,
    AddFilesDialog,
    CreateFolderDialog,
    CreateEvidenceDialog,
    EditEvidenceDialog,
    EditItemDialog,
    DeleteItemDialog,
  },
  props: {
    loadItems: {
      type: Function,
      // async folder => {
      //              folder: { id: '', name: '', filesApiPath: '', folderApiPath: '' },
      //              items: [{ id: '', type: 'file' | 'folder', name: '', editable: true, web_url: ''  }]
      //           }
      required: true,
    },
    getFileUrl: {
      type: Function, // async file => 'http://this.is.a.s3.url'
      required: true,
    },
    getEvidence: {
      type: Function, // async evidenceId => { id: '', .... }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    moveItem: {
      type: Function, // async (item, destinationFolder, currentFolder) => null
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    shareRootFolder: {
      type: Function, // async () => { hasSharingPermissions: false, sharing_link: '' }
      default: () => ({
        hasSharingPermissions: true,
      }),
    },
    filesStorageType: {
      type: String,
      required: true,
    },
    storageEmail: {
      type: String,
      default: '',
    },
    canAddAsEvidence: {
      type: Boolean,
      default: true,
    },
    canAddFile: {
      type: Boolean,
      default: true,
    },
    initialFolder: {
      type: Object,
      default: () => {
        return { id: '', name: '', editable: false, isRootFolder: true };
      },
    },
    pageBreadcrumbs: {
      type: Array,
      require: true,
    },
    showShareWarning: {
      type: Boolean,
      default: false,
    },
    storageSetUp: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['afterFileLaunch', 'volunteeredAcceptSharing'],
  data: () => ({
    items: [],
    sharingLink: null,
    backupSharingLink: null,
    processing: true,
    error: '',
    folder: null,
    folderStack: [],
    folderJSON: '',
    addFilesDialog: { active: false },
    createFolderDialog: { active: false },
    createEvidenceDialog: {
      active: false,
      fileId: '',
      title: '',
    },
    editEvidenceDialog: {
      active: false,
      evidence: {},
    },
    editItemDialog: {
      active: false,
      item: {},
    },
    deleteItemDialog: {
      active: false,
      item: {},
    },
    sharingPollInterval: null,
    errorSnackbar: {
      active: false,
      message: '',
    },
  }),
  async created() {
    if (!this.$route.query.folder) {
      this.folder = this.initialFolder;
      await this.updateItems();
    }
  },
  // Think this will change with vue 3
  beforeUnmount() {
    clearInterval(this.sharingPollInterval);
  },
  computed: {
    ...mapState(['breadcrumbs']),
    moveTargets() {
      const moveTargets = [];
      if (this.folderStack.length > 0) {
        const parent = this.folderStack[this.folderStack.length - 1];
        moveTargets.push({ ...parent, name: `${parent.name} (parent folder)`, icon: 'arrow-up' });
      }
      return moveTargets.concat(
        this.items.filter(x => x.type === 'folder' && x.isMoveTarget).map(x => ({ ...x, icon: x.icon.name }))
      );
    },
    canOpenFolderInDrive() {
      return (
        (this.filesStorageType === 'One Drive' || this.filesStorageType === 'Google Drive') &&
        this.folder.editable &&
        this.folder.webUrl
      );
    },
    urlFolderJSON: {
      get() {
        // folderJSON is not a computed itself because we only want it to change once when both folder and folderStack are updated
        return this.folderJSON;
      },
      set(x) {
        try {
          const json = JSON.parse(x);
          // Ideally should validate what's in here matches the output of createFolderJSON
          // This will be important if we change the data in folder and folderStack
          this.folder = json.folder;
          this.folderStack = json.folderStack;
        } catch (e) {
          if (x) {
            console.log(e);
          }
          this.folder = this.initialFolder;
          this.folderStack = [];
        }
        // Has to manually set the folderJSON (rather than relying on the folderStack watch) as otherwise the sync query
        // will replace folderJSON with the default value in data()
        this.folderJSON = createFolderJSON(this.folder, this.folderStack);
        this.updateItems();
      },
    },
    itemsWithMovable() {
      return this.items.map(i => {
        const movable = this.movable(i);
        const showSecondAction = movable ? i.actions.length === 1 : i.actions.length === 2;
        return {
          ...i,
          movable,
          showSecondAction,
          showMenu: movable || !showSecondAction,
          menuActionsSlice: movable ? 1 : 2,
        };
      });
    },
  },
  watch: {
    folderStack: {
      handler(folderStack) {
        if (this.folder) {
          this.folderJSON = createFolderJSON(this.folder, folderStack);
        }

        if (folderStack) {
          const pageBreadcrumb = this.pageBreadcrumbs[this.pageBreadcrumbs.length - 1];
          const otherPageBreadcrumbs = this.pageBreadcrumbs.slice(0, this.pageBreadcrumbs.length - 1);
          if (!pageBreadcrumb || !pageBreadcrumb.to) {
            throw `FilesList requires pageBreadcrumbs that contain at least one breadcrumb for the current page that has a 'to' field`;
          }

          const pageBreadcrumbQuery = pageBreadcrumb.to.query ? { ...pageBreadcrumb.to.query } : {};

          const breadcrumbs = [
            ...otherPageBreadcrumbs,
            {
              // The pageBreadcrumb and the root folder represent the same place in the hierarcy, but can have different names
              // Therefore we pick to use the pageBreadcrumb and filter out the root folder in other places
              ...pageBreadcrumb,
              to: {
                ...pageBreadcrumb.to,
                query: { ...pageBreadcrumbQuery, folder: createFolderJSON(this.initialFolder, []) },
              },
            },
            ...folderStack
              .filter(f => !f.isRootFolder)
              .map((x, i) => {
                const xFolderStack = folderStack.slice(0, i + 1);
                return { text: x.name, to: this.createRouterTo(x, xFolderStack, pageBreadcrumb) };
              }),
          ];

          if (!this.folder.isRootFolder) {
            breadcrumbs.push({ text: this.folder.name });
          }

          this.$store.commit('setPageInformation', {
            breadcrumbs,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    createRouterTo(folder, folderStack, pageBreadcrumb) {
      pageBreadcrumb = pageBreadcrumb || this.pageBreadcrumbs[this.pageBreadcrumbs.length - 1];
      const pageBreadcrumbQuery = pageBreadcrumb.to.query ? { ...pageBreadcrumb.to.query } : {};
      const query = { ...pageBreadcrumbQuery, folder: createFolderJSON(folder, folderStack) };
      return { name: pageBreadcrumb.to.name, params: { ...this.$route.params, ...pageBreadcrumb.to.params }, query };
    },
    movable(item) {
      if (this.moveTargets.length === 0) return false;
      if (item.type === 'folder') return false;
      if (!item.editable) return false;
      return true;
    },
    renderItemSubtitle(item) {
      return item.type === 'file'
        ? `Last modified: ${fromNowOrNow(item.lastModified)}`
        : item.childCount != null
        ? this.renderChildCount(item.childCount)
        : null;
    },
    renderChildCount(childCount) {
      const quantifier = childCount === 0 ? 'no' : childCount;
      const unit = childCount != 1 ? 'sub-folders/files' : 'sub-folder/file';
      return 'Contents: ' + quantifier + ' ' + unit;
    },
    async updateItems() {
      this.processing = true;
      this.error = '';
      try {
        const data = await this.loadItems(this.folder);
        this.items = data.items
          .map(x => {
            const actions = [];
            if (x.type === 'file') {
              if (this.filesStorageType === 'Mosaic') {
                actions.push({ icon: 'download', label: 'Download', click: item => this.download(item) });
              }
              if (x.editable) {
                actions.push({ icon: 'pencil', label: 'Rename', click: item => this.editItem(item) });
                actions.push({ icon: 'delete', label: 'Delete', click: item => this.deleteItem(item) });
              }
            } else {
              if (x.editable) {
                actions.push({ icon: 'pencil', label: 'Rename', click: item => this.editItem(item) });
                actions.push({ icon: 'delete', label: 'Delete', click: item => this.deleteItem(item) });
              }
            }
            return {
              ...x,
              actions,
              to: x.type === 'folder' ? this.createRouterTo(x, this.folderStack.concat([this.folder])) : undefined,
            };
          })
          .sort((a, b) => {
            if (a.type !== b.type) {
              return a.type === 'folder' ? -1 : 1;
            } else if ((a.roleId && !b.roleId) || (b.roleId && !a.roleId)) {
              return a.roleId ? -1 : 1;
            } else {
              const aName = a.sortName.toLowerCase();
              const bName = b.sortName.toLowerCase();
              return aName > bName ? 1 : -1;
            }
          });

        this.folder = data.folder;
        this.sharingLink = data.sharingLink;
        this.backupSharingLink = data.backupSharingLink;
      } catch (e) {
        this.error = `Sorry, cannot connect to ${this.filesStorageType} right now. Please try again later.`;
        console.log(e);
      }
      this.processing = false;
    },
    async openFolder(currentFolder) {
      try {
        await this.updateItems();
        this.folderStack.push(currentFolder);
      } catch (e) {
        this.folder = currentFolder;
        throw e;
      }
    },
    async download(item) {
      await this.openFile(item, true);
    },
    async openFile(item, download = false) {
      try {
        let webUrl = item.webUrl;
        if (item.webUrl === 'onRequest') {
          webUrl = await this.getFileUrl(item, download);
        }
        window.open(webUrl, '_blank');
        this.$emit('afterFileLaunch');
      } catch (e) {
        console.log(e);
        let message = '';
        if (e.response?.status === 404) {
          message = 'This file no longer exists';
          this.updateItems();
        } else {
          message = 'Sorry. cannot open this file at the moment';
        }

        this.errorSnackbar = {
          active: true,
          message: message,
        };
      }
    },

    acceptSharing() {
      window.open(this.sharingLink, '_blank');
      this.sharingPollInterval = setInterval(async () => {
        try {
          const sharingResponse = await this.shareRootFolder();
          if (sharingResponse.hasSharingPermissions) {
            this.sharingLink = null;
            clearInterval(this.sharingPollInterval);
          }
        } catch (e) {
          console.log('Polling for sharing response failed. Trying again.', e);
        }
      }, 1000);
    },
    backupAcceptSharing() {
      this.$emit('volunteeredAcceptSharing', this.backupSharingLink);
    },
    linkToEvidence(file) {
      this.createEvidenceDialog = {
        active: true,
        fileId: file.id,
        title: file.name,
      };
    },
    async editEvidence(file) {
      const evidence = await this.getEvidence(file.evidence.id);
      this.editEvidenceDialog = {
        active: true,
        evidence,
      };
    },
    editItem(item) {
      this.editItemDialog = {
        active: true,
        item,
      };
    },
    deleteItem(item) {
      this.deleteItemDialog = {
        active: true,
        item,
      };
    },
    async move(item, destinationFolder) {
      this.processing = true;
      try {
        await this.moveItem(item, destinationFolder, this.folder);
        await this.updateItems();
      } catch (e) {
        let message;
        if (e.response?.status === 423) {
          message = 'Sorry, cannot move this file as it is locked. Please ensure that no-one has this file open.';
        } else {
          console.log(e);
          message = 'Sorry, cannot move this file at the moment';
        }
        this.errorSnackbar = {
          active: true,
          message,
        };
      }
      this.processing = false;
    },
    async goUp() {
      const currentFolder = this.folder;
      this.folder = this.folderStack[this.folderStack.length - 1];
      try {
        this.folderStack.pop();
        await this.updateItems();
      } catch (e) {
        this.folderStack.push(this.folder);
        this.folder = currentFolder;
        throw e;
      }
    },
    openInDrive() {
      window.open(this.folder.webUrl, '_blank');
    },
  },
};

// This converts from the folder data structure to what's stored in the URL
// It's a minimal representation that gets added to by the updateItems call
// The value of this shouldn't change after updateItems returns (as that will add an additional history entry)
// It will result in neater data if this was passed as a prop (.e.g not every consumer uses roleId), but I think this is simpler
function createFolderJSON(folder, folderStack) {
  const convertFolder = folder => ({
    id: folder.id,
    name: folder.name,
    isCourse: !!folder.isCourse,
    isRootFolder: !!folder.isRootFolder,
    roleId: folder.roleId,
    isRootRoleFolder: !!folder.isRootRoleFolder,
  });
  return JSON.stringify({
    folder: convertFolder(folder),
    folderStack: folderStack.map(convertFolder),
  });
}
</script>
