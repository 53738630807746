<template>
  <div>
    <mosaic-configure-preview-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      v-model="configurePreview"
      object-type="Course Activity Template Page"
      :load="load"
      :error="sectionError"
    >
      <template #configure>
        <mosaic-save-card
          :save="save"
          object-type="Course Activity Template Page"
          :can-save="canSave"
          :hide-return="true"
          :readonly="published"
          :return-to="{
            name: 'TutorAdminAssignmentTemplateEditPage',
            params: { id: $route.params.assignmentTemplateId },
          }"
        >
          <div class="d-flex">
            <mosaic-card-title class="flex-grow-1"
              >{{ isReviewPage ? 'Review' : section ? section.name : '' }}
              <template #chip>
                <assignment-templates-published-draft-chip :published="published" mode="card" />
              </template>
            </mosaic-card-title>
            <v-btn
              class="ml-2"
              :disabled="!previousSectionId"
              @click="previousSectionId ? changeSection(previousSectionId) : undefined"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn
              class="ml-2"
              :disabled="!nextSectionId"
              @click="nextSectionId ? changeSection(nextSectionId) : undefined"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <template v-if="!isReviewPage">
            <!-- add 'scormPackage' to supported-item-types to enable scorm packages -->
            <mosaic-content-layout-builder
              v-model:content-layout="nullableContentLayout"
              :editable="!published"
              :get-resource-url-prefix="`/assignment-templates/sections/resources`"
              :presign-url="`/presign/institutions/${selectedInstitution.id}/assignment-template-resources`"
              :save-resource-url="`/institutions/${selectedInstitution.id}/assignment-templates/sections/resources`"
              :save-resource-params="{ assignmentTemplateSectionId: sectionId }"
              :supported-item-types="[
                'text',
                'resource',
                'comment',
                'taskList',
                'video',
                'youtube',
                'fileUpload',
                'link',
                'image',
                'columns',
              ]"
              @valid="contentLayoutValid = $event"
            />
          </template>
          <template v-else>
            <div class="pb-4">
              <div class="py-2">
                The "Review" page is for Instructors to review the {{ traineeNounCapitalised() }}'s Course Activity. It
                becomes editable when the {{ traineeNounCapitalised() }} has completed the other pages of the Course
                Activity and becomes visible to the {{ traineeNounCapitalised() }} when the Course Activity is marked as
                reviewed.
              </div>
              <mosaic-card-heading>Grading</mosaic-card-heading>
              <div v-if="judgementSets.length === 0"><no-judgement-sets-alert class="my-2" /></div>
              <mosaic-checkbox
                v-model="showGrade"
                name="review-page-enabled"
                label="Grade this Course Activity?"
                prepend-icon="mdi-pencil-box-multiple"
                hide-details
                density="compact"
                class="mt-2"
                :disabled="published || judgementSets.length === 0"
              />
              <div v-if="showGrade" class="pt-2">
                <mosaic-judgement-set-select
                  v-model="reviewPageGradeJudgementSetId"
                  :readonly="published"
                  :items="judgementSets"
                  hide-details
                  class="pb-0"
                />
              </div>
            </div>
            <div>
              <mosaic-card-heading>Other Content</mosaic-card-heading>
              <mosaic-content-layout-builder
                v-model:content-layout="nullableContentLayout"
                :editable="!published"
                :get-resource-url-prefix="`/assignment-templates/review-page/resources`"
                :presign-url="`/presign/institutions/${selectedInstitution.id}/assignment-template-resources`"
                :save-resource-url="`/institutions/${selectedInstitution.id}/assignment-templates/review-page/resources`"
                :save-resource-params="{ assignmentTemplateId: assignmentTemplateId }"
                :supported-item-types="[
                  'text',
                  'resource',
                  'comment',
                  'taskList',
                  'video',
                  'youtube',
                  'link',
                  'image',
                  'columns',
                  'fileUpload',
                ]"
                @valid="contentLayoutValid = $event"
              />
            </div>
          </template>
        </mosaic-save-card>
      </template>

      <template #preview>
        <assignment-card
          v-if="studentAssignment"
          :can-edit-assignment="false"
          :can-review="true"
          :can-mark-as-reviewed="false"
          :student-assignment="studentAssignment"
          :section-id="sectionId"
          :preview="true"
          file-upload-presign-url="not-a-url"
          @update:section-id="changeSection($event)"
          @click-section="changeSection($event)"
        />
      </template>
    </mosaic-configure-preview-page>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Course Activity Template Page"
      :save="save"
    />
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { parseRouteId } from '@/composables/vue-router';
import { computed, ref, watch } from 'vue';
import type { AssignmentTemplate } from './assignment-templates';
import { watchEffect } from 'vue';
import type { ContentLayout } from '@/utils/content-layout';
import { mapContentLayoutToCompletionComments, mapContentLayoutToCompletionTasks } from '@/utils/content-layout';
import type { JudgementSet } from '@/store/map-store';
import { mapState } from '@/store/map-store';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import AssignmentCard from './AssignmentCard.vue';
import { notNullableRef } from '@/composables/vue';
import type { ConfigurePreview } from '@/components/library/configure-preview/configure-preview';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { mapActions } from '@/store/map-store';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import type { StudentAssignment } from './student-assignments';
import AssignmentTemplatesPublishedDraftChip from './AssignmentTemplatesPublishedDraftChip.vue';
import NoJudgementSetsAlert from '@/components/templates/NoJudgementSetsAlert.vue';
import { useApi } from '@/composables/api';

const api = useApi();

const { selectedInstitution, judgementSets } = mapState();
const { loadJudgementSets } = mapActions();
loadJudgementSets();

const assignmentTemplateId = parseRouteId('assignmentTemplateId');
const sectionId = parseRouteId('id');
const isReviewPage = computed(() => sectionId.value === -1);

const template = ref<AssignmentTemplate | null>(null);
const section = computed(() => template.value?.assignmentTemplateSections.find(s => s.id === sectionId.value));
const nextSectionId = computed(() => {
  if (!section.value || !template.value) return null;
  if (section.value.order === template.value.assignmentTemplateSections.length - 1) return -1;
  return template.value?.assignmentTemplateSections.find(s => s.order === section.value!.order + 1)?.id;
});
const previousSectionId = computed(() => {
  if (!template.value) return null;
  if (isReviewPage.value)
    return template.value.assignmentTemplateSections.find(
      s => s.order === template.value!.assignmentTemplateSections.length - 1
    )?.id;
  if (!section.value) return null;
  return template.value?.assignmentTemplateSections.find(s => s.order === section.value!.order - 1)?.id;
});

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Activity Templates`,
      to: {
        name: 'TutorAdminAssignmentTemplatesListPage',
      },
    },
    {
      text: template.value?.name || '',
      to: {
        name: 'TutorAdminAssignmentTemplateEditPage',
        params: { id: template.value?.id.toString() || '-1' },
      },
    },
    {
      text: isReviewPage.value ? 'Review Page' : section.value?.name || '',
    },
  ])
);

const published = computed(() => template.value?.status === 'published');

const nullableContentLayout = ref<ContentLayout | null>(null);
const contentLayout = notNullableRef(nullableContentLayout, 'contentLayout');

const showGrade = ref(false);
const reviewPageGradeJudgementSetId = ref<number | null>(null);
watchEffect(() => {
  if (judgementSets.value.length > 0 && !reviewPageGradeJudgementSetId.value) {
    reviewPageGradeJudgementSetId.value = judgementSets.value[0].id;
  }
});

const dirty = computed<boolean>(() => {
  if (isReviewPage.value) {
    const contentLayoutDirty =
      JSON.stringify(nullableContentLayout.value) !== JSON.stringify(template.value?.reviewPageContentLayout);
    const gradeDirty = template.value?.reviewPageGradeJudgementSetId
      ? reviewPageGradeJudgementSetId.value !== template.value?.reviewPageGradeJudgementSetId || !showGrade.value
      : showGrade.value;
    return contentLayoutDirty || gradeDirty;
  } else {
    const contentLayoutDirty =
      JSON.stringify(nullableContentLayout.value) !== JSON.stringify(section.value?.contentLayout);
    return contentLayoutDirty;
  }
});
const { dialog } = useUnsavedChanges(dirty);

const sectionError = ref(false);
watch([section, () => template.value?.reviewPageContentLayout], () => {
  if (section.value) {
    sectionError.value = false;
    nullableContentLayout.value = section.value.contentLayout;
  } else if (isReviewPage.value && template.value) {
    sectionError.value = false;
    nullableContentLayout.value = template.value?.reviewPageContentLayout || null;
  } else {
    sectionError.value = true;
  }
});

const configurePreview = ref<ConfigurePreview>(null);
const route = useRoute();
async function load() {
  const r = await api.get<AssignmentTemplate>(`/assignment-templates/${assignmentTemplateId.value}`);
  template.value = r.data;
  showGrade.value = !!r.data.reviewPageGradeJudgementSetId;
  reviewPageGradeJudgementSetId.value = r.data.reviewPageGradeJudgementSetId;

  // if preview not set then based on whether published set preview
  configurePreview.value =
    route.query.preview === 'preview'
      ? 'preview'
      : route.query.preview === 'configure'
      ? 'configure'
      : r.data.status === 'published'
      ? 'preview'
      : 'configure';
}

const studentAssignment = computed<StudentAssignment | null>(() => {
  if (!template.value) return null;

  let reviewPageGradeJudgementSet: JudgementSet | null = null;
  if (showGrade.value) {
    if (judgementSets.value.length == 0) return null;
    const judgmentSet = judgementSets.value.find(js => js.id == reviewPageGradeJudgementSetId.value);
    if (!judgmentSet) {
      throw `Can't find judgement set with id ${reviewPageGradeJudgementSetId.value}`;
    }
    reviewPageGradeJudgementSet = judgmentSet;
  }
  const assignmentTemplate = {
    name: template.value.name,
    roleId: template.value.roleId,
    isTraineeContributor: template.value.isTraineeContributor,
    reviewPageGradeJudgementSet,
    reviewPageContentLayout: isReviewPage.value ? contentLayout.value : template.value?.reviewPageContentLayout,
    assignmentTemplateSections: template.value.assignmentTemplateSections.map(s => {
      if (s.id === sectionId.value) {
        return { ...s, contentLayout: contentLayout.value };
      }
      return s;
    }),
  };

  const a: StudentAssignment = {
    id: -1,
    status: 'not_started',
    reviewPageGradeJudgementDescriptor: reviewPageGradeJudgementSet?.institutionJudgementDescriptors[0] || null,
    reviewPageCompleted: false,
    versionNumber: null,
    latestVersionNumber: null,
    nextVersionStatus: null,
    studentId: -1,
    requiredChanges: '',
    previousVersionRequiredChanges: '',
    curriculumStatements: [],
    assignment: {
      id: -1,
      dueDate: '',
      name: assignmentTemplate.name,
      roleId: assignmentTemplate.roleId,
      isTraineeContributor: assignmentTemplate.isTraineeContributor,
      assignmentTemplate,
    },
    studentAssignmentReviewPageComments: mapContentLayoutToCompletionComments(
      assignmentTemplate.reviewPageContentLayout,
      id => ({
        assignmentTemplateReviewPageCommentId: id,
      })
    ),
    studentAssignmentReviewPageTasks: mapContentLayoutToCompletionTasks(
      assignmentTemplate.reviewPageContentLayout,
      id => ({
        assignmentTemplateReviewPageTaskId: id,
      })
    ),
    studentAssignmentReviewPageFiles: [],
    studentAssignmentSections: assignmentTemplate.assignmentTemplateSections.map(s => ({
      id: s.id,
      assignmentTemplateSectionId: s.id,
      completed: false,
      studentAssignmentSectionComments: mapContentLayoutToCompletionComments(s.contentLayout, id => ({
        assignmentTemplateSectionCommentId: id,
      })),
      studentAssignmentSectionTasks: mapContentLayoutToCompletionTasks(s.contentLayout, id => ({
        assignmentTemplateSectionTaskId: id,
      })),
      studentAssignmentSectionFiles: [],
    })),
  };
  return a;
});

const contentLayoutValid = ref(false);
const canSave = computed<boolean>(
  () => Boolean(contentLayoutValid.value && (!showGrade.value || reviewPageGradeJudgementSetId.value)) && dirty.value
);
const triggerBackgroundLoad = ref(false);
async function save() {
  if (!isReviewPage.value) {
    await api.put<{ contentLayout: ContentLayout }, void>(`/assignment-templates/sections/${sectionId.value}/content`, {
      contentLayout: contentLayout.value,
    });
  } else {
    await api.put<
      {
        reviewPageContentLayout: ContentLayout;
        reviewPageGradeJudgementSetId: number | null;
      },
      void
    >(`/assignment-templates/${assignmentTemplateId.value}/review-page`, {
      reviewPageContentLayout: contentLayout.value,
      reviewPageGradeJudgementSetId: showGrade.value ? reviewPageGradeJudgementSetId.value : null,
    });
  }
  triggerBackgroundLoad.value = true;
}

const router = useRouter();
function changeSection(sectionId: number) {
  router.push({
    name: 'TutorAdminAssignmentTemplateSectionPage',
    params: {
      id: sectionId.toString(),
    },
    query: {
      preview: configurePreview.value,
    },
  });
}
</script>
